import React from 'react';
import { Box, Heading, Skeleton, SkeletonText } from '@chakra-ui/react';

type LoadingProps = {
	isLoading: true;
};

type ResolvedProps = {
	name: string;
	message: string;
	isLoading?: false;
};

type StaticProps = {
	children: React.ReactElement;
};

export type WelcomeBannerProps = (LoadingProps | ResolvedProps) & StaticProps;

const WelcomeBanner = (props: WelcomeBannerProps) => (
	<Box
		bgColor="gray.100"
		display="flex"
		flexDirection={{ base: 'column', lg: 'row' }}
		alignItems="center"
		justifyContent={{ base: 'initial', lg: 'center' }}
		p={{ base: 0, lg: 5 }}
		pb={{ base: 8, lg: 20 }}
		mb={8}
		pos="relative"
	>
		{/* <Box px={{base: 2, lg: 0}} zIndex={1}> */}
		<Box
			px={{ base: 2, lg: 0 }} zIndex={1}
			// position="relative"
			p={{ base: 6, lg: 20 }}
			mb={{ base: '-3rem', lg: 0 }}
			mr={{ base: 0, lg: '-3rem' }}
			w={{ base: 'full', lg: '75ch'}}
			bgGradient="linear(to-br, blue.500, purple.200)"
			className='new-home-welcom-banner-width-style'
		>
			<Box>
				<Skeleton isLoaded={!props.isLoading}>
					<Heading color="white">
						Hey, {props.isLoading ? '' : props.name}!
					</Heading>
				</Skeleton>

				<Heading
					size="2a"
					mt={2}
					color="white"
					lineHeight={{ base: '32px', lg: '64px' }}
				>
					<SkeletonText
						noOfLines={3}
						skeletonHeight="1em"
						isLoaded={!props.isLoading}
					>
						{props.isLoading ? '' : props.message}
					</SkeletonText>
				</Heading>
			</Box>

			<Box
				h={{ base: '3rem', lg: 0 }}
				w={{ base: 0, lg: '4rem' }}
				d={{ base: 'block', lg: 'inline' }} />
		</Box>

		<Box px={{ base: 2, lg: 0 }} zIndex={1}>
			{props.children}
		</Box>
	</Box>
);

export default WelcomeBanner;
