import React from 'react';
import Link from 'next/link';
import { Container, HStack, IconButton, Text, Skeleton, Button, VStack } from '@chakra-ui/react';
import HomeHorizontalScrollContainer from 'src/components/pages/home/home-horizontal-scroll-container';
import { FavoritesBoardResponse } from 'src/lib/types';
import { EditIcon } from 'src/theme/icons';
import WorkoutCard from 'src/components/common/workout-card';

type LoadingProps = {
	isLoading: true;
};

type ResolvedProps = {
	isLoading?: false;
	group: FavoritesBoardResponse;
};

type CommonProps = {
	onCreateNewGroup: () => void;
};

export type FavoritesBoardContainerProps = (LoadingProps | ResolvedProps) & CommonProps;

const FavoritesBoardContainer = (props: FavoritesBoardContainerProps) => {
	let workoutLength = 0;
	if (!props.isLoading) {
		workoutLength = props.group.workouts.length;
	}
	return (
		<>
			<Container maxW="container.xl">
				<HStack align="center">
					<Skeleton isLoaded={!props.isLoading}>
						<Text variant="intro">
							{props.isLoading ? 'A sample group' : props.group.title}
						</Text>
					</Skeleton>

					<Link passHref href={`/favorites?id=${props.isLoading ? 0 : props.group.id}`}>
						<IconButton
							icon={<EditIcon />}
							aria-label="Edit category"
							variant="ghost"
							color="black"
							as="a" />
					</Link>
				</HStack>

				{
					!props.isLoading && props.group.workouts.length === 0 && (<Container maxW="container.xl" paddingLeft={0}>
						<Text mt={4}>
							You currently have no favorites in this category. When you find a workout that you love, click the star to save and add to a board.
						</Text>
						<Link passHref href={`/workouts`}>
							<Button
								variant="ghost"
								mt={{ base: 4, lg: 8 }}
								className="new-home-general-favorite-button-style"
							>
								VIEW ALL WORKOUTS
							</Button>
						</Link>
					</Container>
					)
				}
			</Container>

			<Container
				maxW="container.xl"
				px={0}
				paddingBottom='35px'
			>
				<HomeHorizontalScrollContainer
					//  borderBottomWidth="0px"
					activepos={0}
					activewidth={0}
					lengthWorkout={workoutLength}
				>
					{
						props.isLoading && Array.from({ length: 4 }).map((_, i) => (
							<WorkoutCard key={i} isLoading onCreateNewGroup={props.onCreateNewGroup} />
						))
					}

					{
						!props.isLoading && props.group.workouts.map(workout => (
							<WorkoutCard
								key={workout.id}
								onCreateNewGroup={props.onCreateNewGroup}
								{...workout} />
						))
					}
				</HomeHorizontalScrollContainer>
			</Container>
		</>
	);
};

export default FavoritesBoardContainer;
