import React, { useMemo, useState, useEffect } from 'react';
import Link from 'next/link';
import { Box, Heading, Text, Skeleton, HStack, Button, Spacer, VStack, useBreakpointValue } from '@chakra-ui/react';
import { WorkoutResponse } from 'src/lib/types';
import AddToCalendarButton from 'src/components/common/add-to-calendar-button';
import getCalendarEventForWorkout from 'src/lib/get-calendar-event-for-workout';
import dateToStr from 'src/lib/date-to-str';
import parseDateStr from 'src/lib/parse-date-str';
import DaySelector, { DAYS, DayT } from './day-selector';

type LoadingProps = {
	isLoading: true;
};

type ResolvedProps = {
	isLoading?: false;
	workouts: WorkoutResponse[];
};

export type WeeklyWorkoutPlanProps = LoadingProps | ResolvedProps;

const WeeklyWorkoutPlan = (props: WeeklyWorkoutPlanProps) => {
	const calendarButtonVariant = useBreakpointValue<'icon' | 'text'>({ base: 'icon', md: 'text' });
	const [shouldShow, setShouldShow] = useState(true);
	const [selectedDay, setSelectedDay] = useState<DayT>(DAYS[Math.max(new Date().getDay(), 1)] as DayT);

	const selectedWorkout = useMemo(() => {
		if (props.isLoading) {
			return undefined;
		}

		const selectedDayIndex = DAYS.indexOf(selectedDay);

		return props.workouts.find(workout => parseDateStr(workout.date).getDay() - 1 === selectedDayIndex - 1);
	}, [props, selectedDay]);

	const formattedDate = useMemo(() => {
		let d = new Date();

		if (selectedWorkout) {
			d = parseDateStr(selectedWorkout.date);
		}

		return dateToStr(d);
	}, [selectedWorkout]);

	const calendarEvent = useMemo(() => getCalendarEventForWorkout(selectedWorkout ? selectedWorkout : undefined), [selectedWorkout]);

	const disabledDays = useMemo(() => {
		if (props.isLoading) {
			return [];
		}

		const result: DayT[] = [];
		for (const [i, DAY] of DAYS.entries()) {
			const workoutForDay = props.workouts.find(workout => parseDateStr(workout.date).getDay() === i);

			if (!workoutForDay) {
				result.push(DAY as DayT);
			}
		}

		return result;
	}, [props]);

	// Sometimes the default day is disabled
	useEffect(() => {
		if (disabledDays.includes(selectedDay)) {
			for (const day of DAYS) {
				if (!disabledDays.includes(day as DayT)) {
					setShouldShow(true);
					setSelectedDay(day as DayT);
					return;
				}
			}

			// All days are disabled
			setShouldShow(false);
		}
	}, [disabledDays, selectedDay]);

	return (
		<Box
			shadow="card"
			bgColor="white"
			rounded={{ base: '20px', md: '6xl' }}
			borderTopLeftRadius={{ base: 0, md: 0 }}
			py={{ base: 4, md: 8 }}
			px={{ base: 5, md: 10 }}
			w={{ base: 'initial', md: 'lg' }}
			position="relative"
			display={shouldShow ? 'block' : 'none'}
		>
			<VStack align="flex-start" spacing={{ base: 6, md: 8 }}>
				<DaySelector
					selected={selectedDay}
					stackProps={{
						width: '100%',
						justifyContent: 'space-between'
					}}
					disabledDays={disabledDays}
					isDisabled={props.isLoading}
					onChange={d => {
						setSelectedDay(d);
					}} />

				<Skeleton isLoaded={!props.isLoading}>
					<Heading size="4">{formattedDate}</Heading>

					<Heading size="3">
						{selectedWorkout?.type ?? 'Upper body'}
					</Heading>
				</Skeleton>

				<Box>
					<Skeleton isLoaded={!props.isLoading}>
						<Text>
							{selectedWorkout?.minLength ?? '40'} - {selectedWorkout?.maxLength ?? '60'} minutes
						</Text>
					</Skeleton>

					<Skeleton isLoaded={!props.isLoading}>
						<Text>
							{selectedWorkout?.equipment ?? 'Treadmill'}
						</Text>
					</Skeleton>

					<Skeleton isLoaded={!props.isLoading}>
						<Text>
							{selectedWorkout?.exerciseEquipment ? `Optional: ${selectedWorkout?.exerciseEquipment}` : ""}
						</Text>
					</Skeleton>
				</Box>
			</VStack>

			<HStack align="center" w="full" mt={{ base: 0, md: 6 }}>
				<AddToCalendarButton
					isLoading={props.isLoading}
					event={calendarEvent}
					variant={calendarButtonVariant ?? 'icon'} />

				<Spacer />

				<Link passHref href={`/workouts/${selectedWorkout?.id ?? 0}`}>
					<Button variant="primary" disabled={(props.isLoading ?? false) || !selectedWorkout} as="a">
						Start
					</Button>
				</Link>
			</HStack>
		</Box>
	);
};

export default WeeklyWorkoutPlan;
