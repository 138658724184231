import React, { useEffect, useMemo } from 'react';
import {
	Box,
	Heading,
	Container,
	SimpleGrid,
	useDisclosure
} from '@chakra-ui/react';
import WelcomeBanner from 'src/components/pages/home/welcome-banner';
import useCurrentUser from 'src/lib/hooks/use-current-user';
import useChallenges from 'src/lib/hooks/use-challenges';
import { useCookies } from 'react-cookie';
import { AllFavoritesBoardResponse, ChallengeResponse, CustomNextPage, WeeklyResponse } from 'src/lib/types';
import WeeklyWorkoutPlan from 'src/components/pages/home/weekly-workout-plan';
import ChallengePromoCard from 'src/components/pages/home/challenge-promo-card';
import FacebookPromo from 'src/components/pages/home/facebook-promo';
import useCustomSWR from 'src/lib/hooks/use-custom-swr';
import CreateFavoritesBoardModal from 'src/components/common/create-favorites-board-modal';
import FavoritesBoardContainer from 'src/components/pages/home/favorites-group-container';
import FavoritesGeneralGroup from 'src/components/pages/home/favorites-general-group';
import HomeNotes from 'src/components/pages/home/home-notes';

const ChallengesGridContainer = ({ challenges, isLoading }: { challenges: ChallengeResponse[]; isLoading?: boolean }) => (
	<SimpleGrid
		minChildWidth={{ base: '100vw', lg: 'var(--chakra-sizes-xl)' }}
		spacing={10}
		mt={10}
		mx={-4}
	>
		{
			isLoading ? Array.from({ length: 4 }).fill(null).map((_, i) => (
				<ChallengePromoCard key={i} isLoading />
			)) : challenges.map(challenge => (
				<ChallengePromoCard
					key={challenge.id}
					heading={challenge.title}
					numOfWeeks={challenge.data.length}
					imageUrl={challenge.image}
					href={`/challenges/${challenge.id}`} />
			))
		}
	</SimpleGrid>
);

const HomePage: CustomNextPage = () => {
	const createNewFavoritesBoardDisclosure = useDisclosure();
	const { user, isLoading: isUserLoading } = useCurrentUser();
	const { data: favoriteGroups, isLoading: areFavoriteGroupsLoading } = useCustomSWR<AllFavoritesBoardResponse>('users/favorites');
	const { data: weeklyWorkouts, isLoading: areWeeklyWorkoutsLoading } = useCustomSWR<WeeklyResponse>('weekly');
	const { pastChallenges, activeChallenges, isLoading: areChallengesLoading } = useChallenges();

	// const upTo4PastChallenges = useMemo(() => pastChallenges.slice(0, 4), [pastChallenges]);
	const upTo4PastChallenges = useMemo(() => pastChallenges, [pastChallenges]);

	return (
		<Box>
			<WelcomeBanner
				isLoading={isUserLoading || areWeeklyWorkoutsLoading}
				name={user?.name ?? ''}
				message={weeklyWorkouts?.message ?? ''}
			>
				<WeeklyWorkoutPlan
					isLoading={areWeeklyWorkoutsLoading}
					workouts={weeklyWorkouts?.workouts ?? []}
				/>
			</WelcomeBanner>

			{
				(areFavoriteGroupsLoading || favoriteGroups?.length) !== 0 && (
					<Container maxW="container.xl">
						<Heading size="1"
						// variant="highlighted"
						// mb={4}
						>
							Favorites
						</Heading>
						<div className='new-favorites-header-text-bg-style'>&nbsp;</div>
					</Container>
				)
			}

			{
				areFavoriteGroupsLoading ? Array.from({ length: 2 }).map((_, i) => (
					<FavoritesBoardContainer
						key={i}
						isLoading
						onCreateNewGroup={() => { /* empty */ }} />
				)) : favoriteGroups?.map(group => (
					<FavoritesBoardContainer
						key={group.id}
						group={group}
						onCreateNewGroup={createNewFavoritesBoardDisclosure.onOpen} />
				))
			}

			<Container maxW="container.xl">
				{
					activeChallenges.length > 0 && (
						<>
							<Heading size="1" variant="highlighted">
								Active Challenges
							</Heading>

							<ChallengesGridContainer challenges={activeChallenges} />
						</>
					)
				}
			</Container>

			{/* <FavoritesGeneralGroup
				title="General Favorites"
			/> */}

			<HomeNotes />

			<Box bgColor="gray.100" pb={20} mt={10}>
				<Container maxW="container.xl">
					<Heading size="1"
					// variant="highlighted"
					>
						Past Challenges
					</Heading>
					<div className='new-home-past-challlenge-header-text-bg-style'>&nbsp;</div>

					<ChallengesGridContainer isLoading={areChallengesLoading} challenges={upTo4PastChallenges} />
				</Container>
			</Box>

			<FacebookPromo
				w="full"
				py={{ base: 8, lg: 16 }}
				px={{ base: 4, lg: 32 }}
				mb={-8} />

			<CreateFavoritesBoardModal
				isOpen={createNewFavoritesBoardDisclosure.isOpen}
				onClose={createNewFavoritesBoardDisclosure.onClose} />
		</Box>
	);
};

HomePage.title = 'Home';

export default HomePage;
