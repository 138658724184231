import {useMemo} from 'react';
import parseDateString from '../parse-date-str';
import {AllChallengesResponse} from '../types';
import useCustomSWR from './use-custom-swr';

const useChallenges = () => {
	const {data, isLoading} = useCustomSWR<AllChallengesResponse>('challenges/all?orderBy=date&order=desc&per=25');

	const {pastChallenges, activeChallenges} = useMemo(() => {
		const pastChallenges = [];
		const activeChallenges = [];

		if (data) {
			const now = new Date();

			for (const challenge of data) {
				const start = parseDateString(challenge.startDate);
				const end = parseDateString(challenge.endDate);

				if (start <= now && now <= end) {
					activeChallenges.push(challenge);
				} else if (end < now) {
					pastChallenges.push(challenge);
				}
			}
		}

		return {pastChallenges, activeChallenges};
	}, [data]);

	return {
		pastChallenges,
		activeChallenges,
		isLoading
	};
};

export default useChallenges;
