import React from 'react';
import {Box, Heading, Button, BoxProps} from '@chakra-ui/react';
import Image from 'next/image';
import Logo from '../../../../public/images/logo.svg';
import FacebookLogo from '../../../../public/images/facebook-logo.png';

const FacebookPromo = (props: BoxProps) => (
	<Box
		bgGradient={{
			base: 'linear(to-b, blue.400, teal.300)',
			lg: 'linear(to-r, blue.400, blue.400 42%, teal.300 100%)'
		}}
		display="flex"
		alignItems="center"
		justifyContent="space-between"
		pos="relative"
		overflow="hidden"
		{...props}
	>
		<Box>
			<Heading size="2a" color="white" maxW="container.md">
				Did you know that you have access to an accountability group for people doing the TL Method?
			</Heading>

			<Button
				as="a"
				variant="ghost"
				href="https://www.facebook.com/groups/tlmethod"
				target="_blank"
				mt={{base: 4, lg: 8}}
			>
				Check In
			</Button>
		</Box>

		<Box
			position="relative"
			display={{base: 'none', lg: 'block'}}
			boxSize="sm"
			flexShrink={0}
			my={-32}
		>
			<Box w="full" height="auto">
				<Image src={Logo} layout="fill"/>
			</Box>

			<Box pos="absolute" right={0} bottom={14} boxSize={24}>
				<Image src={FacebookLogo} layout="fill"/>
			</Box>
		</Box>
	</Box>
);

export default FacebookPromo;
