import React from 'react';
import {HStack, Button, StackProps} from '@chakra-ui/react';

// Even though workouts never fall on Sunday, we include it here to be consistent with Date.getDay()
export const DAYS = [
	'SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'
] as const;

export const DAYS_WITHOUT_SUNDAY = [
	'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'
] as const;

export type DayT = typeof DAYS_WITHOUT_SUNDAY[number];

export type DaySelectorProps = {
	isDisabled?: boolean;
	disabledDays?: DayT[];
	selected: DayT;
	onChange: (newSelection: DayT) => void;
	stackProps?: StackProps;
};

const DaySelector = (props: DaySelectorProps) => {
	return (
		<HStack {...props.stackProps} position="relative" zIndex="0">
			{
				DAYS_WITHOUT_SUNDAY.map(day => (
					<Button
						key={day}
						isActive={props.selected === day}
						disabled={(props.isDisabled ?? false) || props.disabledDays?.includes(day)}
						rounded="full"
						boxSize={10}
						p={0}
						letterSpacing="normal"
						alignItems="center"
						borderWidth="2px"
						borderColor="yellow.400"
						bg="transparent"
						overflow="hidden"
						position="relative"
						_hover={
							props.isDisabled ? ({}) : ({
								_after: {
									opacity: 0.2
								}
							})
						}
						_active={{
							_after: {
								opacity: 1,
								bgGradient: 'linear(to-br, teal.300, yellow.400)'
							}
						}}
						_after={{
							content: '\'\'',
							position: 'absolute',
							zIndex: -1,
							rounded: 'full',
							left: 0,
							top: 0,
							right: 0,
							bottom: 0,
							bgGradient: 'linear(to-br, white, yellow.400)',
							opacity: 0,
							transitionProperty: 'common',
							transitionDuration: 'normal'
						}}
						onClick={() => {
							props.onChange(day);
						}}
					>
						{day[0]}
					</Button>
				))
			}
		</HStack>
	);
};

export default DaySelector;
