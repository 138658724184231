import React, { useRef, useState, useEffect } from 'react';
import { HStack, StackProps, IconButton, Grid, GridItem } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

export type HomeHorizontalScrollContainerProps = {
	borderBottomWidth?: string;
	activepos?: number;
	activewidth?: number;
	lengthWorkout: number;
} & StackProps;

const HomeHorizontalScrollContainer = React.forwardRef(({ children, ...props }: HomeHorizontalScrollContainerProps, ref?: React.Ref<HTMLDivElement>) => {
	const stackRef = useRef<HTMLDivElement>(null);
	const scrollRef = useRef<HTMLDivElement>(null);
	const scrollStep = 252;

	const [didMount, setDidMount] = useState(false)
	const [visibleLeftScroll, setVisibleLeftScroll] = useState(true)
	const [visibleRightScroll, setVisibleRightScroll] = useState(false)
	const [scrollPosition, setScrollPosition] = useState(0)
	const [activePos, setActivePos] = useState(0)
	const [activeWidth, setActiveWidth] = useState(0)

	function updateScrollPos(pos: any) {
		setScrollPosition(Number(pos));
		// if (pos > 0) setVisibleLeftScroll(true); else setVisibleLeftScroll(false)
		if (stackRef && stackRef.current && scrollRef && scrollRef.current) {
			if (pos < stackRef.current.scrollWidth - scrollRef.current.clientWidth) setVisibleRightScroll(true); else setVisibleRightScroll(false)
		}
		if (stackRef && stackRef.current) {
			stackRef.current.scrollTo(pos, 0);
		}
	}

	// const displayWindowSize = () => {
	// 	if (stackRef && stackRef.current && scrollRef && scrollRef.current) {
	// 		console.log("ref: ", stackRef.current.scrollWidth - scrollRef.current.clientWidth);
	// 		if (stackRef.current.scrollWidth - scrollRef.current.clientWidth > 0) {
	// 			setVisibleRightScroll(true);
	// 		} else {
	// 			setVisibleRightScroll(false);
	// 		}
	// 	}
	// }

	const displayArrowChecker = () => {
		if (!didMount) {
			// setVisibleLeftScroll(false)
			if (stackRef && stackRef.current && scrollRef && scrollRef.current) {
				if (stackRef.current.scrollWidth - scrollRef.current.clientWidth > 0) {
					setVisibleRightScroll(true);
				}
			}
			updateScrollPos(0);
			setDidMount(true);
		} else {
			if (props?.activepos && props?.activewidth) {
				if (activePos !== props?.activepos || activeWidth !== props?.activewidth) {
					if (stackRef && stackRef.current && scrollRef && scrollRef.current) {
						if (props?.activepos + props?.activewidth / 2 > scrollRef.current.clientWidth / 2) {
							if (props?.activepos * 2 + props?.activewidth > scrollRef.current.clientWidth) {
								let predictPos = props?.activepos + props?.activewidth / 2 - scrollRef.current.clientWidth / 2;
								let maxPos = stackRef.current.scrollWidth - scrollRef.current.clientWidth;
								updateScrollPos(predictPos < maxPos ? predictPos : maxPos);
							}
						}
					}

					setActivePos(props?.activepos ?? 0);
					setActiveWidth(props?.activewidth ?? 0);
				}
			}
		}
	}
	window.addEventListener("resize", displayArrowChecker);


	const handleLeftControll = () => {
		let newScrollPosition = scrollPosition;
		// if (newScrollPosition - scrollStep >= 0) newScrollPosition = newScrollPosition - scrollStep;
		newScrollPosition = newScrollPosition - scrollStep;
		updateScrollPos(newScrollPosition);

		// if (stackRef && stackRef.current && scrollRef && scrollRef.current) {
		// 	let maxPos = stackRef.current.scrollWidth - scrollRef.current.clientWidth;
		// 	console.log("left: ", newScrollPosition, maxPos)
		// }
	}

	const handleRightControll = () => {
		let newScrollPosition = scrollPosition;
		// if (stackRef && stackRef.current && scrollRef && scrollRef.current) {
		// 	if (newScrollPosition + scrollStep <= stackRef.current.scrollWidth - scrollRef.current.clientWidth) newScrollPosition = newScrollPosition + scrollStep;
		// }
		newScrollPosition = newScrollPosition + scrollStep;
		updateScrollPos(newScrollPosition);

		// if (stackRef && stackRef.current && scrollRef && scrollRef.current) {
		// 	let maxPos = stackRef.current.scrollWidth - scrollRef.current.clientWidth;
		// 	console.log("right: ", newScrollPosition, maxPos)
		// }
	}

	useEffect(() => {
		displayArrowChecker();
	}, [stackRef, props])

	return (
		<HStack
			ref={stackRef}
			display="flex"
			overflowX="scroll"
			css={{ '&::-webkit-scrollbar': { display: 'none' }, "background-color": "transparent" }}
			{...props}
		>
			{props.lengthWorkout > 4 && <IconButton
				aria-label=''
				icon={<ChevronLeftIcon color={'#4747B0'} boxSize={30} />}
				display={{ base: "none", lg: "flex" }}
				onClick={handleRightControll}
				// opacity={visibleLeftScroll ? 1 : 0}
				className="home-favorite-workout-controller-left-style"
			/>}

			{children}

			{props.lengthWorkout > 4 && <IconButton
				aria-label=''
				icon={<ChevronRightIcon color={'#4747B0'} boxSize={30} />}
				display={{ base: "none", lg: "flex" }}
				onClick={handleLeftControll}
				// opacity={visibleRightScroll ? 1 : 0}
				className="home-favorite-workout-controller-right-style"
			/>}
		</HStack>
	);
});

export default HomeHorizontalScrollContainer;
