import React, { useMemo } from 'react';
import Link from 'next/link';
import { Spacer, HStack, Heading, Text, Skeleton, LinkBox, LinkOverlay } from '@chakra-ui/react';
import getPseudoRandomAccentColorSchemeFromString from 'src/lib/get-random-accent-color-scheme-from-string';
import getAccentColorFromScheme from 'src/lib/get-accent-color-from-scheme';
import getGradientFromScheme from 'src/lib/get-gradient-from-scheme';
import dateToStr from 'src/lib/date-to-str';
import { WorkoutResponse } from 'src/lib/types';
import getCalendarEventForWorkout from 'src/lib/get-calendar-event-for-workout';
import parseDateStr from 'src/lib/parse-date-str';
import AddToCalendarButton from '../add-to-calendar-button';
import AddToFavoritesButton, { AddToFavoritesButtonProps } from '../add-to-favorites-button';

type LoadingProps = {
	isLoading: true;
};

type ResolvedProps = {
	isLoading?: false;
} & WorkoutResponse;

type CommonProps = {
	onCreateNewGroup: AddToFavoritesButtonProps['onCreateNewGroup'];
};

export type WorkoutCardProps = CommonProps & (LoadingProps | ResolvedProps);

const WorkoutCard = (props: WorkoutCardProps) => {
	const formattedDate = useMemo(() => {
		let d = new Date();

		if (!props.isLoading) {
			d = parseDateStr(props.date);
		}

		return dateToStr(d);
	}, [props]);

	const colorScheme = getPseudoRandomAccentColorSchemeFromString(props.isLoading ? '' : props.type);

	const calendarEvent = useMemo(() => getCalendarEventForWorkout(props.isLoading ? undefined : props), [props]);

	return (
		<LinkBox
			flexShrink={0}
			display="inline-flex"
			w={{ base: '95vw', md: '16rem' }}
			// width='15vw'
			shadow="card"
			borderTopEndRadius="2xl"
			borderBottomEndRadius="2xl"
			borderLeftWidth={4}
			bgColor="white"
			borderLeftColor={props.isLoading ? 'white' : getAccentColorFromScheme(colorScheme)}
			_hover={{
				cursor: props.isLoading ? 'not-allowed' : 'pointer',
				_before: {
					opacity: 1
				}
			}}
			position="relative"
			overflow="hidden"
			transform="translateZ(0)"
			_before={{
				transform: 'translateZ(0)',
				content: '\'\'',
				position: 'absolute',
				left: 0,
				top: 0,
				right: 0,
				bottom: 0,
				bgGradient: props.isLoading ? 'none' : getGradientFromScheme(colorScheme),
				zIndex: -1,
				transitionProperty: 'common',
				transitionDuration: 'normal',
				opacity: 0
			}}
			p={4}
			minH="15.625rem"
			flexDir="column"
		>
			<HStack mb={4} zIndex={10} pos="relative">
				<Spacer />

				<AddToFavoritesButton
					isLoading={props.isLoading}
					variant="icon"
					workoutId={props.isLoading ? 0 : props.id}
					onCreateNewGroup={props.onCreateNewGroup} />

				<Spacer w={2} flex={0} />

				<AddToCalendarButton isLoading={props.isLoading} variant="icon" event={calendarEvent} />
			</HStack>

			<Skeleton
				isLoaded={!props.isLoading}
				mb="auto"
			>
				<Heading size="4" colorScheme="red">{formattedDate}</Heading>
				<Heading size="3">
					<Link passHref href={props.isLoading ? '#' : `/workouts/${props.id}`}>
						<LinkOverlay>
							{props.isLoading ? 'Upper Body' : props.type}
						</LinkOverlay>
					</Link>
				</Heading>
				{/* <p>{props.tags}</p> */}
			</Skeleton>

			<Skeleton isLoaded={!props.isLoading} mt={8}>
				<Text>
					{props.isLoading ? '40' : props.minLength} - {props.isLoading ? '60' : props.maxLength} minutes
				</Text>
			</Skeleton>

			<Skeleton isLoaded={!props.isLoading}>
				<Text>
					{props.isLoading ? 'Treadmill' : props.equipment}
				</Text>
			</Skeleton>

			{!props.isLoading && props?.exerciseEquipment && <Skeleton isLoaded={!props.isLoading}>
				<Text>
					{props.isLoading ? 'Treadmill' : `Optional: ${props.exerciseEquipment}`}
				</Text>
			</Skeleton>}

		</LinkBox>
	);
};

export default React.memo(WorkoutCard);
