import {ThemeTypings} from '@chakra-ui/styled-system';

const getGradientFromScheme = (scheme: ThemeTypings['colorSchemes'], direction = 'to-b', firstColor?: string) => {
	switch (scheme) {
		case 'red':
			return `linear(${direction}, ${firstColor ?? 'red.300'}, red.100)`;
		case 'purple':
			return `linear(${direction}, ${firstColor ?? 'purple.200'}, purple.100)`;
		case 'teal':
			return `linear(${direction}, ${firstColor ?? 'teal.600'}, teal.400)`;
		case 'blue':
			return `linear(${direction}, ${firstColor ?? 'blue.200'}, blue.50)`;
		case 'yellow':
			return `linear(${direction}, ${firstColor ?? 'yellow.400'}, yellow.100)`;
		default:
			return `linear(${direction}, ${firstColor ?? 'red.300'}, red.100)`;
	}
};

export default getGradientFromScheme;
