import {CurrentUserResponse} from '../types';
import useCustomSWR from './use-custom-swr';

const useCurrentUser = () => {
	const result = useCustomSWR<CurrentUserResponse>('users');

	return {...result, user: result.data};
};

export default useCurrentUser;
