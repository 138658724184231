import React from 'react';
import { Box, Heading, Button, BoxProps } from '@chakra-ui/react';
import NextLink from 'next/link';
import Image from 'next/image';
import HomeNotesImg from '../../../../public/images/home_notes.png';
import HomeNotesArrowImg from '../../../../public/images/home_notes_arrow_icon.png';

const HomeNotes = (props: BoxProps) => (
	<Box className="new-home-notes-section-container-style"	>
		<Image src={HomeNotesImg} width={"24px"} height={"24px"} layout="fixed" />
		<NextLink passHref href={"/account/notes"}>
			<Box className='new-home-notes-sub-container-style'>
				<span className="new-home-notes-text-margin-style">See all of your notes in one place.</span>
				<span className="new-home-notes-go-page-text-style">View exercises notes</span>
				<Image src={HomeNotesArrowImg} />
			</Box>
		</NextLink>
	</Box>
);

export default HomeNotes;
